@use "global/mixin" as m;
@use "global/variable" as v;

.c-listBox {
  border: 1px solid v.$black;
  margin-top: 60px;
  margin-bottom: 80px;
  @include m.sp {
    width: 90%;
    margin: 60px auto;
  }
  &_ttl {
    @include m.fz(24);
    color: v.$white;
    font-weight: bold;
    text-align: center;
    background: v.$green;
    padding: 16px 0;
  }
  &_cont {
    width: 90%;
    max-width: 778px;
    padding: 30px 0;
    margin: 0 auto;
    &_cmn {
      @include m.fz(18);
      font-weight: bold;
      color: v.$black;
      padding-left: 50px;
      position: relative;
      @include m.sp {
        @include m.fz(16);
        line-height: 1.5em;
      }
      & + .c-listBox_cont_cmn {
        margin-top: 28px;
      }
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &::before {
        width: 23px;
        height: 23px;
        border: 1px solid v.$gray;
      }
      &::after {
        width: 9px;
        height: 18px;
        border-right: 4px solid v.$green;
        border-bottom: 4px solid v.$green;
        transform: translateY(-60%) rotate(45deg);
        left: 6px;
      }
    }
  }
}
