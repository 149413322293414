@use "global/mixin" as m;
@use "global/variable" as v;

.c-flow {
  display: block;
  color: v.$gray;
  border: 1px solid v.$gray;
  background: v.$white;
  &_cont {
    display: flex;
    align-items: center;
    position: relative;
    counter-increment: number;
    & + .c-flow_cont {
      border-top: 1px solid v.$gray;
      &::before {
        content: "";
        display: block;
        width: 38px;
        height: 38px;
        background: v.$green;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 141px;
        transform: translateY(-50%);
        @include m.sp {
          left: 20%;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: v.$white transparent transparent transparent;
        border-width: 10px 8px 0 8px;
        position: absolute;
        top: 0;
        left: 152.5px;
        transform: translateY(-50%);
        @include m.sp {
          left: 23%;
        }
      }
    }
  }
  &_ttl {
    display: block;
    width: 320px;
    min-height: 136px;
    // border-right: 1px solid v.$gray;
    position: relative;
    @include m.sp {
      width: 160px;
    }
    span {
      display: block;
      width: 100%;
      @include m.fz(18);
      line-height: 1.5em;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding-left: 46px;
      @include m.sp {
        text-align: left;
      }
    }
    &::before {
      content: counter(number);
      @include m.fz(18);
      color: v.$white;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      z-index: 1;
    }
    &::after {
      content: "";
      display: block;
      width: 40px;
      height: 95%;
      background: v.$green;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &_txt {
    width: calc(100% - 320px);
    min-height: 136px;
    border-left: 1px solid v.$gray;
    box-sizing: border-box;
    padding: 10px 20px;
    @include m.sp {
      width: calc(100% - 160px);
    }
    &_inner {
      @include m.fz(14);
      line-height: 1.5em;
    }
  }
}
