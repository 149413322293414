@use "global/mixin" as m;
@use "global/variable" as v;

.memberList {
  &_categry {
    margin-top: 100px;
    &_ttl {
      @include m.sp {
        text-align: center;
      }
      &_en {
        @include m.fz(28);
        @include m.sp {
          display: block;
        }
      }
      &_jp {
        background: v.$black;
        @include m.fz(16);
        font-weight: bold;
        color: v.$white;
        padding: 4px 20px;
        margin-left: 20px;
        @include m.sp {
          display: inline-block;
          margin: 20px auto;
        }
      }
    }
    &_inner {
      display: flex;
      flex-wrap: wrap;
      margin-top: 42px;
      & * {
        @include m.fz(16);
        line-height: 1.8em;
      }
    }
    &_cmn {
      width: calc(225 / 1024 * 100%);
      position: relative;
      padding-bottom: 80px;
      @include m.sp {
        width: 48%;
      }
      & + .memberList_categry_cmn {
        margin-left: calc(40 / 1024 * 100%);
        @include m.sp {
          margin-left: 2%;
        }
      }
      &:nth-child(n + 5) {
        margin-top: calc(40 / 1024 * 100%);
      }
      &:nth-child(4n + 1) {
        margin-left: 0;
      }
      @include m.sp {
        &:nth-child(n + 3) {
          margin-top: 36px;
        }
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }
      &_fig {
        width: 100%;
        padding-top: calc(295 / 225 * 100%);
        position: relative;
        overflow: hidden;
        img {
          width: auto;
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &_name {
        @include m.fz(21);
        font-weight: bold;
        margin-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid v.$green;
        @include m.sp {
          padding-bottom: 8px;
        }
        &_en {
          @include m.fz(14);
          font-weight: 400;
          color: v.$green;
          margin-left: 4px;
          white-space: nowrap;
        }
      }
      &_office {
        margin-top: 10px;
      }
      &_skill {
        padding-bottom: 18px;
        margin-top: 16px;
      }
      &_link {
        display: block;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid v.$black;
        position: absolute;
        left: 0;
        bottom: 0;
        a {
          display: block;
          background: v.$green;
          font-weight: bold;
          text-align: center;
          color: v.$white;
          padding: 22px 0;
        }
      }
    }

    &.typeTop {
      .memberList_categry_cmn {
        width: 45%;
        @include m.sp {
          width: 100%;
        }
        & + .memberList_categry_cmn {
          margin-left: 10%;
          @include m.sp {
            margin-left: 0;
            margin-top: 40px;
          }
        }
      }
    }
    @include m.sp {
      &.typenormal {
        .memberList_categry_cmn_name {
          @include m.fz(16);
          line-height: 1.5em;
          .memberList_categry_cmn_name_en {
            display: block;
            @include m.fz(10);
            line-height: 1.5em;
          }
        }
        .memberList_categry_cmn_office,
        .memberList_categry_cmn_skill_cmn,
        .memberList_categry_cmn_link a {
          @include m.fz(14);
          line-height: 1.5em;
        }
      }
    }
  }
}

.contentsMemberSingle {
  .memberDetail {
    & * {
      @include m.fz(16);
      line-height: 1.8em;
    }
    &_top {
      display: flex;
      justify-content: space-between;
      @include m.sp {
        display: block;
      }
      figure {
        width: calc(440 / 1024 * 100%);
        @include m.sp {
          width: 100%;
        }
      }
      &_txtarea {
        width: calc(440 / 1024 * 100%);
        display: flex;
        flex-direction: column;
        @include m.sp {
          width: 100%;
          display: block;
        }
      }
      &_name {
        @include m.fz(21);
        font-weight: bold;
        margin-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid v.$green;
        &_en {
          @include m.fz(14);
          font-weight: 400;
          color: v.$green;
          margin-left: 6px;
          white-space: nowrap;
        }
      }
      &_sub {
        border-bottom: 1px solid v.$black;
        padding-bottom: 16px;
        margin-top: 12px;
      }
      &_office {
        margin-top: 10px;
      }
      &_skill {
        margin-top: 16px;
      }
      &_txt {
        margin-top: 20px;
      }
    }

    &_cmn {
      margin-top: 115px;
      @include m.sp {
        margin-top: 60px;
      }
      &_ttl {
        @include m.fz(28);
        font-weight: 400;
        border-bottom: 1px solid v.$green;
        padding-bottom: 14px;
        margin-bottom: 46px;
      }
    }
    &_biography {
      &_main,
      tbody {
        display: block;
        width: 100%;
      }
      tr {
        // display: block;
        width: 100%;
        display: flex;

        & + tr {
          border-top: 1px solid v.$black;
        }
      }
      th {
        width: 136px;
        padding: 8px 0;
        @include m.sp {
          width: 100px;
        }
      }
      td {
        width: calc(100% - 136px);
        border-left: 1px solid v.$black;
        padding: 8px 0 8px 20px;
        @include m.sp {
          width: calc(100% - 100px);
        }
      }
    }
  }
}

.mamberBtnLink {
  display: block;
  background: v.$green;
  font-weight: bold;
  text-align: center;
  color: v.$white;
  padding: 22px 0;
  max-width: 600px;
  width: 80%;
  margin: 70px auto 50px;
  @include m.sp {
    @include m.fz(14);
    line-height: 1.5em;
  }
}
