@use "global/mixin" as m;
@use "global/variable" as v;

// TOPページ

.pageTop {
  .business {
    &_list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &_cmn {
        width: calc(100% / 3);
        padding-top: calc(100% / 3);
        background: #333;
        position: relative;
        @include m.sp {
          width: calc(100% / 2);
          padding-top: calc(100% / 2);
        }
        &:nth-child(1) {
          background: url(../img/index/business_img_01.png) center center /
            cover no-repeat;
        }
        &:nth-child(2) {
          background: url(../img/index/business_img_02.png) center center /
            cover no-repeat;
        }
        &:nth-child(3) {
          background: url(../img/index/business_img_03.png) center center /
            cover no-repeat;
        }
        &:nth-child(4) {
          background: url(../img/index/business_img_04.png) center center /
            cover no-repeat;
        }
        &:nth-child(5) {
          background: url(../img/index/business_img_05.png) center center /
            cover no-repeat;
        }
        &:nth-child(6) {
          background: url(../img/index/business_img_06.png) center center /
            cover no-repeat;
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box;
          padding: 18px 20px;
          transition: 0.3s all ease;
          p {
            display: block;
            width: calc(260 / 340 * 100%);
            height: 58px;
            background: rgba(164, 203, 98, 1);
            position: absolute;
            bottom: 0;
            right: 0;
            transition: 0.3s all ease;
            @include m.sp {
              height: calc(32 / 158 * 100%);
            }
            &::after {
              content: "";
              display: block;
              width: 18px;
              height: 18px;
              box-sizing: border-box;
              border-top: 3px solid v.$white;
              border-right: 3px solid v.$white;
              position: absolute;
              top: 50%;
              right: 20px;
              transform: rotate(45deg) translateY(-50%);
              transform-origin: center top;
              @include m.sp {
                width: 10px;
                height: 10px;
                border-top: 2px solid v.$white;
                border-right: 2px solid v.$white;
                right: 10px;
              }
            }
            span {
              @include m.fz(20);
              font-weight: bold;
              color: v.$white;
              position: absolute;
              top: 50%;
              left: 20px;
              transform: translate(0, -50%);
              transition: 0.3s all ease;
              @include m.sp {
                @include m.fz_w(16);
                line-height: 1em;
              }
            }
          }
          &:hover {
            p {
              width: 100%;
              height: 100%;
              background: rgba(164, 203, 98, 0.6);
            }
            span {
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .news {
    position: relative;
    z-index: 1;
    .secCmn_inner {
      position: relative;
    }
    &_list {
      width: calc(100% - 348px);
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      @include m.sp {
        position: static;
        margin-top: 80vw;
      }
      &_inner {
        background: v.$white;
        border: 1px solid v.$green;
        box-sizing: border-box;
        padding: 35px 56px;
        @include m.sp {
          padding: 20px;
        }
      }
      &_cmn {
        a {
          display: block;
        }
      }
      &_cmn + .news_list_cmn {
        margin-top: 35px;
      }
      &_ttl {
        border-bottom: 1px solid v.$black;
        padding-bottom: 10px;
        align-items: center;
        .date {
          @include m.fz(16);
          font-weight: bold;
          color: v.$green;
        }
        .ttl {
          @include m.fz(20);
          font-weight: bold;
          margin-left: 30px;
          @include m.sp {
            @include m.fz(16);
            line-height: 1.5em;
            margin-left: 0;
          }
        }
      }
      &_txt {
        margin-top: 14px;
        @include m.sp {
          @include m.fz(14);
          line-height: 1.5em;
        }
      }
      .btnCmn-long {
        margin-top: 0;
      }
    }
    &_fig {
      @include m.sp {
        width: 100vw;
        height: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        img {
          width: auto;
          height: 100%;
          position: absolute;
          top: 0;
          left: 70%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .topics {
    margin-top: -124px;
    @include m.sp {
      margin-top: 50px;
    }
    &_main {
      border: 1px solid v.$green;
      display: flex;
      justify-content: space-between;
      @include m.sp {
        display: block;
        border: none;
      }
      &_entry {
        width: 360px;
        background: v.$green;
        box-sizing: border-box;
        padding: 42px 52px;
        @include m.sp {
          width: 100%;
        }
        &_ttl {
          @include m.fz(28);
          font-family: v.$font_yumin;
          text-align: center;
          color: v.$white;
          &::before {
            content: "";
            display: block;
            width: 29px;
            height: 23px;
            background: url(../img/common/icon_mail_w.svg) center center / cover
              no-repeat;
            margin: 0 auto 24px;
          }
        }
        &_txt {
          font-weight: bold;
          color: v.$white;
          margin-top: 32px;
        }
        &_btn {
          margin-top: 44px;
          a {
            position: relative;
            background: v.$white;
            @include m.fz(18);
            font-weight: bold;
            text-align: center;
            color: v.$green;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            padding: 40px 0;
            transition: 0.2s all ease;
            &::before {
              content: "";
              display: block;
              width: 54px;
              height: 100%;
              background: v.$white;
              position: absolute;
              top: 0;
              left: -53px;
              transition: 0.2s all ease;
            }
            &:hover {
              background: v.$black;
              &::before {
                background: v.$black;
              }
            }
          }
        }
      }
      &_list {
        width: calc(100% - 400px);
        padding-top: 43px;
        padding-bottom: 90px;
        position: relative;
        @include m.sp {
          width: 90%;
          padding-bottom: 0;
          margin: 0 auto;
        }
        &_inner {
          padding-right: 26px;
        }
        &_cmn {
          & + .topics_main_list_cmn {
            margin-top: 24px;
          }
          a {
            display: flex;
            align-items: flex-end;
            font-weight: bold;
            box-sizing: border-box;
            @include m.sp {
              flex-wrap: wrap;
            }
            .date {
              width: 100px;
              border-bottom: 1px solid v.$black;
              @include m.sp {
                border-bottom: none;
              }
            }
            .num {
              width: 62px;
              color: v.$orange;
              box-sizing: border-box;
              border-bottom: 1px solid v.$orange;
              margin-bottom: -1px;
              @include m.sp {
                position: relative;
                z-index: 1;
              }
            }
            .txt {
              padding-left: 6px;
              border-bottom: 1px solid v.$black;
              @include m.sp {
                border-bottom: none;
                border-top: 1px solid v.$black;
                padding-top: 10px;
              }
            }
          }
        }
        .btnCmn-long {
          position: absolute;
          bottom: 0;
          right: 0;
          @include m.sp {
            position: static;
          }
        }
      }
    }
  }

  .recruit {
    .c-ttlCmn {
      margin-bottom: 0;
    }
    &_main {
      width: 100%;
      height: calc(576 / 1388 * 100vw);
      background: url(../img/index/recruit_img.jpg) 20% center / cover no-repeat;
      margin-top: -25px;
      @include m.sp {
        height: auto;
        background: none;
        margin-top: -20px;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 90vw;
          background: url(../img/index/recruit_img.jpg) 20% center / cover
            no-repeat;
          margin-top: -20px;
        }
      }
      .secCmn_inner {
        height: 100%;
        @include m.sp {
          height: auto;
          margin-top: 20px;
        }
      }
      &_inner {
        width: 40%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 108px;
        margin-left: auto;
        margin-right: 0;
        position: relative;
        @include m.sp {
          width: 100%;
          height: auto;
          padding-top: 0;
        }
        .btnCmn-long {
          position: absolute;
          bottom: 0;
          right: 0;
          @include m.sp {
            position: static;
          }
        }
      }
      &_txt {
        @include m.fz(24);
        line-height: 2em;
        font-family: v.$font_yumin;
        color: v.$white;
        text-shadow: 5px 5px 5px v.$black;
        @include m.sp {
          color: v.$black;
          text-shadow: none;
        }
      }
    }
  }
}
