@use "global/mixin" as m;
@use "global/variable" as v;

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}

html {
  overflow-x: hidden;
}

body {
  font-family: v.$font_noto;
  overflow-x: hidden;
  * {
    @include m.fz(16);
    line-height: 1.8em;
    box-sizing: border-box;
  }
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: v.$black;
}

.secCmn {
  width: 100%;
  margin-top: 100px;
  @include m.sp {
    margin-top: 60px;
  }
  &_inner {
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
    @include m.sp {
      width: 100%;
    }
    &.type02 {
      max-width: 976px;
    }
    &_in {
      box-sizing: border-box;
      padding: 30px 40px;
      background: v.$white;
      @include m.sp {
        padding: 0;
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  &.bg-green {
    background: v.$green02;
    padding: 80px 0;
    @include m.sp {
      padding: 30px 0;
    }
  }
}

.flexBox {
  display: flex;
  justify-content: space-between;
  @include m.sp {
    display: block;
  }
  &.reFlex {
    flex-direction: row-reverse;
  }
}
.sp-w {
  @include m.sp {
    width: 90% !important;
    margin: 0 auto;
  }
}

.mt-40 {
  margin-top: 40px;
}
