@use "global/mixin" as m;
@use "global/variable" as v;

.c-txtArea {
  &_ttl {
    @include m.fz(28);
    line-height: 1.5em;
    font-weight: 500;
    font-family: v.$font_yumin;
    margin-bottom: 20px;
    &::after {
      content: "";
      display: block;
      width: 70%;
      max-width: 183px;
      height: 1px;
      background: v.$green;
      margin-top: 20px;
    }
  }
  &_txt {
    @include m.fz(16);
    line-height: 1.8em;
    padding-left: -1em;
    text-indent: 1em;
  }
}
