@use "global/mixin" as m;
@use "global/variable" as v;

.c-fill {
  background-color: v.$white;
  padding: 64px;

  @include m.sp {
    padding: 2.4rem;
  }
}
