// fontsize
@mixin fz($font_size, $b: "") {
  font-size: #{$font_size}px;
  font-size: #{calc($font_size / 10)}rem;
  line-height: 1em;
  @if ($b == "") {
  } @else {
    font-weight: #{$b};
  }
}
@mixin fz_w($font_size, $b: "") {
  font-size: calc(#{$font_size - 1} / 375 * 100vw);
  // font-size: #{$font_size / 10}rem;
  line-height: 1.5em;
  @if ($b == "") {
  } @else {
    font-weight: #{$b};
  }
}

// break popint
$SPblake5: 320px;
$SPblake: 767px;
$TABblake: 800px;
$PCblake: 1030px;

@mixin sp($break-point: 767px) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}
@mixin tab($break-point: 800px) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}
@mixin mintab($break-point: 800px) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}
@mixin pc($break-point: 767px) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}
@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

.pc {
  @include sp($SPblake) {
    display: none !important;
  }
}
.sp {
  @include pc($SPblake) {
    display: none !important;
  }
}
.tab {
  @include pc($TABblake) {
    display: none !important;
  }
}
.tabnone {
  @include sp($TABblake) {
    display: none !important;
  }
}

//@at-root　階層かくし
@mixin atroot($selecter) {
  @at-root {
    #{$selecter} {
      @content;
    }
  }
}

//selectparts
@mixin label_radio_checkbox($kind: "checkbox", $color: #f00) {
  margin-right: 40px;
  padding-left: 24px;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #000;
  &:before {
    width: 14px;
    height: 14px;
    border: solid 1px #bcbcbc;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: "";
    transform: translate(0, -50%);
  }
  &:after {
    position: absolute;
    display: block;
    content: "";
  }
  &:hover {
    &:before {
      border-color: $color;
    }
  }
  &.disabled {
    color: #bcbcbc;
  }
  &.checked {
    &:before {
      border-color: $color;
    }
  }
  input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  @if $kind == "radio" {
    &:before {
      border-radius: 100%;
    }
    &:after {
      background: #bcbcbc;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      top: 50%;
      left: 4px;
      transform: translate(0, -50%);
    }
    &.checked {
      &:after {
        background: $color;
      }
    }
  } @else if $kind == "checkbox" {
    &:after {
      width: 10px;
      height: 6px;
      box-sizing: border-box;
      border: solid 2px;
      border-color: transparent;
      top: 50%;
      left: 1px;
      transform-origin: left top;
      transform: rotate(-45deg);
    }
    &.checked {
      &:after {
        border-color: transparent transparent $color $color;
      }
    }
  }
}
//サイト固有のミックスイン

// * hack IE
$hack-ie11: "*::-ms-backdrop";
@mixin hack-ie($hack-ver: "") {
  // * IE8 hack
  @if $hack-ver == "ie8" {
    @media \0screen {
      @content;
    }
  }
  // * IE9-10 hack(add propary \9)
  @else if $hack-ver == "ie9-10" {
    @media all and (min-width: 0\0) {
      @content;
    }
  }
  // * IE10 hack(add propary \9)
  @else if $hack-ver == "ie10" {
    @media all and (-ms-high-contrast: none\0) {
      @content;
    }
  }
  // * IE11 hack(add selector *::-ms-backdrop)
  @else if $hack-ver == "ie11" {
    @media all and (-ms-high-contrast: none\0) {
      @content;
    }
  }
}

/* ========================================================================== */
/* animation ========= */
/* ========================================================================== */

.js-fade-in-up {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
  transition: all 0.7s ease-out 0.2s;
  &.animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.js-fade-in-up[data-delay="head"] {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
  transition: all 0.4s ease-out;
  transition-delay: 1.5s;
  &.animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.js-fade-in {
  opacity: 0;
  transition: opacity 0.7s ease-out;
  &.animated {
    opacity: 1;
  }
}

//サイト固有のミックスイン
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sp {
    flex-direction: column;
  }
}
