@use "global/mixin" as m;
@use "global/variable" as v;

.contentsArchive {
  .archiveList {
    &_cmn {
      margin-top: 60px;
      @include m.sp {
        margin-top: 30px;
      }
      &_top {
        width: 100%;
        display: flex;
      }
      &_ttl {
        width: 85%;
        background: v.$green;
        @include m.fz(18);
        font-weight: bold;
        color: v.$white;
        padding: 16px 40px;
        box-sizing: border-box;
      }
      &_btn {
        width: 15%;
        background: v.$black;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 14px;
          height: 14px;
          border-top: 3px solid v.$white;
          border-left: 3px solid v.$white;
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(225deg);
          transition: 0.2s all ease;
        }
      }

      &_list {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 40px;
        overflow: hidden;
        display: none;
        &_cmn {
          a {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @include m.sp {
              display: block;
            }
          }
          .date {
            width: 94px;
            @include m.fz(18);
            line-height: 1.5em;
            text-align: center;
            @include m.sp {
              display: inline-block;
            }
          }
          .tag {
            width: 90px;
            background: v.$green;
            @include m.fz(16);
            line-height: 1.5em;
            text-align: center;
            color: v.$white;
            border-radius: 23px;
            @include m.sp {
              display: inline-block;
            }
          }
          .txt {
            width: calc(100% - 94px * 2 - 10px);
            @include m.fz(16);
            line-height: 1.5em;
            @include m.sp {
              width: 100%;
              margin-top: 10px;
            }
          }
          & + .archiveList_cmn_list_cmn {
            margin-top: 50px;
          }
        }
      }
      &.active {
        .archiveList_cmn_btn::before {
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }

    &_pager {
      display: flex;
      justify-content: center;
      li {
        width: 30px;
        height: 30px;
        background: v.$black02;
        @include m.fz(14);
        line-height: 30px;
        text-align: center;
        color: v.$white;
        &:hover {
          cursor: pointer;
        }
        & + li {
          margin-left: 10px;
        }
      }
    }
  }
}

.contentsArchiveSingle {
  .archiveMine {
    &_ttl {
      width: 100%;
      background: v.$light-gray;
      @include m.fz(20);
      line-height: 1.5em;
      border-left: 12px solid v.$green;
      padding: 14px 26px;
    }
    &_date {
      @include m.fz(18);
      display: flex;
      align-items: center;
      border-bottom: 1px solid v.$black;
      padding: 0 0 20px 40px;
      margin-top: 42px;
      @include m.sp {
        margin-top: 20px;
      }
      .tag {
        background: v.$green;
        @include m.fz(16);
        line-height: 2em;
        color: v.$white;
        border-radius: 23px;
        padding: 0 14px;
        margin-left: 20px;
      }
    }
    &_inner {
      width: 90%;
      margin: 30px auto;
      * {
        @include m.fz(16);
        line-height: 1.8em;
      }
      h3 {
        @include m.fz(18);
        line-height: 1.5em;
        border-bottom: 2px solid v.$green;
        padding: 10px 0;
      }
      h4 {
        @include m.fz(18);
        font-weight: 400;
        line-height: 1.5em;
        padding: 10px 0;
      }
      ul li {
        @include m.fz(16);
        line-height: 1.5em;
        list-style: disc;
        margin-left: 1.3em;
        & + li {
          margin-top: 10px;
        }
      }
      img {
        display: block;
        max-width: 560px;
        width: 90%;
        margin: 50px auto;
        @include m.sp {
          width: 100%;
          margin: 30px auto;
        }
      }
    }
  }
}
