@use "global/mixin" as m;
@use "global/variable" as v;

.pageAboutUs {
  .greeting {
    &_cmn {
      & + .greeting_cmn {
        margin-top: 122px;
        @include m.sp {
          margin-top: 0;
        }
      }
      &_subFig {
        width: 35%;
        margin-top: 100px;
        @include m.sp {
          width: 100%;
          margin-top: 0;
        }
      }
      &_txtArea {
        width: 60%;
        background: v.$white;
        box-sizing: border-box;
        padding: 40px 32px;
        margin-top: -200px;
        position: relative;
        @include m.sp {
          width: 95%;
          background: v.$white;
          padding: 20px 4%;
          margin: -190px auto 0;
          position: relative;
        }
        &_txt {
          color: v.$gray;
        }
        &_name {
          font-weight: 500;
          text-align: right;
          color: v.$gray;
          margin-top: 14px;
        }
      }
    }
  }

  .philosophy {
    &_main {
      position: relative;
      &_txtArea {
        width: 100%;
        max-width: 652px;
        background: v.$white;
        box-sizing: border-box;
        padding: 34px 42px 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        @include m.sp {
          width: 95%;
          background: ugba(255, 255, 255, 0.7);
          position: relative;
          padding: 20px 4%;
          margin: -190px auto 0;
        }
        &_ttl {
          @include m.fz(38);
          line-height: 1.3em;
          @include m.sp {
            @include m.fz(28);
            line-height: 1.5em;
          }
        }
        &_txt {
          color: v.$black;
        }
      }
    }
    &_thought {
      border-top: 1px solid v.$black;
      border-bottom: 1px solid v.$black;
      box-sizing: border-box;
      padding: 34px 42px 26px 0;
      margin-top: 170px;
      @include m.sp {
        margin-top: 40px;
        padding: 34px 0 26px;
      }
      * {
        color: v.$black02;
      }
      &_txtArea {
        @include m.sp {
          width: 90%;
          margin: 0 auto;
        }
      }
      &_ttl {
        @include m.fz(21);
        font-weight: bold;
        @include m.sp {
          text-align: center;
        }
      }
      &_txt {
        margin-top: 16px;
      }
      &_logoArea {
        position: relative;
        padding-top: 44px;
        margin-top: 40px;
        @include m.sp {
          width: 90%;
          padding-top: 20px;
          margin: 40px auto 0;
        }
        &::before {
          content: "";
          display: block;
          width: 80%;
          height: 1px;
          background: v.$green;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          @include m.sp {
            width: 50%;
          }
        }
        &_logo {
          width: 16%;
          @include m.sp {
            width: 50%;
            margin: 30px auto 0;
          }
        }
        &_txtArea {
          width: 75%;
          @include m.sp {
            width: 100%;
          }
        }
        &_ttl {
          @include m.fz(21);
          font-weight: bold;
          @include m.sp {
            text-align: center;
            line-height: 1.8em;
          }
        }
        &_txt {
          margin-top: 16px;
          @include m.sp {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .vision {
    margin-top: 150px;
    @include m.sp {
      margin-top: 80px;
    }
    &_cmn {
      width: 100%;
      padding: 60px 0;
      @include m.sp {
        padding: 110vw 0 0;
      }
      &_inner {
        width: 100%;
        max-width: 583px;
        background: rgba(255, 255, 255, 0.7);
        box-sizing: border-box;
        padding: 32px 38px;
        @include m.sp {
          padding: 20px 7%;
        }
        .c-txtArea_txt {
          color: v.$black;
        }
      }
    }
    .vision_cmn:nth-child(2) {
      background: url(../img/aboutus/vision_img_01.jpg) 80% 20% / cover
        no-repeat;
      margin-top: -62px;
      @include m.sp {
        background: url(../img/aboutus/vision_img_01_sp.jpg) center 20% / cover
          no-repeat;
        margin-top: 0;
      }
    }
    .vision_cmn:nth-child(3) {
      background: url(../img/aboutus/vision_img_02.jpg) 20% 20% / cover
        no-repeat;
      @include m.sp {
        background: url(../img/aboutus/vision_img_02_sp.jpg) center 20% / cover
          no-repeat;
      }
      .vision_cmn_inner {
        max-width: 650px;
        margin-left: auto;
        margin-right: 0;
        @include m.sp {
          max-width: none;
          margin: 0;
        }
      }
    }
  }

  .strength {
    counter-reset: num 0;
    &_cmn {
      display: flex;
      justify-content: space-between;
      counter-increment: num 1;
      @include m.sp {
        display: block;
      }
      & + .strength_cmn {
        margin-top: 50px;
      }
      * {
        color: v.$black02;
      }
      &_fig {
        width: 45%;
        @include m.sp {
          width: 100%;
        }
      }
      &_txtArea {
        width: 55%;
        @include m.sp {
          width: 90%;
          margin: 30px auto 0;
        }
        &_ttl {
          display: flex;
          align-items: center;
          padding-left: 52px;
          padding-bottom: 22px;
          border-bottom: 1px solid v.$green;
          position: relative;
          @include m.sp {
            padding-left: 0;
          }
          span {
            @include m.fz(21);
            line-height: 1.5em;
            font-weight: bold;
            margin-left: 30px;
            @include m.sp {
              margin-left: 16px;
            }
          }
          &::before {
            display: inline-block;
            content: counter(num);
            width: 65px;
            height: 65px;
            background: v.$green;
            @include m.fz(39);
            line-height: 65px;
            font-family: v.$font_futura;
            text-align: center;
            color: v.$white;
            border-radius: 50%;
            @include m.sp {
              width: 40px;
              height: 40px;
              @include m.fz(25);
              line-height: 40px;
            }
          }
        }
        &_txt {
          padding-left: 52px;
          margin-top: 30px;
          @include m.sp {
            padding-left: 0;
          }
        }
      }
    }
  }
}
