@use "global/mixin" as m;
@use "global/variable" as v;

.c-pageLink {
  width: 100%;
  margin-top: 90px;
  &_inner {
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include m.sp {
      flex-wrap: wrap;
    }
    &.type02 {
      max-width: 752px;
    }
  }
  &_cmn {
    width: 23%;
    max-width: 182px;
    background: v.$green;
    transition: 0.3s all ease;
    @include m.sp {
      width: 45%;
      &:nth-child(n + 3) {
        margin-top: 5%;
      }
    }
    a {
      display: block;
      padding: 16px 14px;
      span {
        display: block;
        @include m.fz(18);
        line-height: 1.3em;
        font-weight: bold;
        text-align: center;
        color: v.$white;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: v.$white;
          position: absolute;
          top: 34px;
          left: 0;
        }
        &::after {
          content: "";
          display: block;
          width: 14px;
          height: 14px;
          box-sizing: border-box;
          border-bottom: 3px solid v.$white;
          border-right: 3px solid v.$white;
          margin: 16px auto 0;
          transform: rotate(45deg);
          transform-origin: center bottom;
        }
      }
    }
    &:hover {
      background: v.$black;
    }
  }
}
