@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-ma {
  &_inner {
    margin: 40px 0;

    @include m.sp {
      margin: 32px 0;
    }
  }

  &_button {
    display: block;
    width: 100%;
    max-width: 580px;
    text-align: center;
    margin: auto;
    padding: 1.5em;
    background-color: v.$green;
    color: v.$white;
    font-weight: 700;
    font-size: 1.6rem;

    @include m.sp {
      width: 90%;
      font-size: 1.4rem;
      padding: 1.5rem;
    }
  }
}
