@use "global/mixin" as m;
@use "global/variable" as v;

.c-image {
  &_120 {
    max-width: 12rem;
    width: 100%;
  }

  &_310 {
    max-width: 31rem;
    width: 100%;
  }

  &_330 {
    max-width: 33rem;
    width: 100%;
  }

  &_370 {
    max-width: 37rem;
    width: 100%;
  }

  &--sp {
    @include m.sp {
      margin: 1rem auto 0;
    }
  }

  &--center {
    margin: 0 auto;
  }
}
