@use "global/mixin" as m;
@use "global/variable" as v;

.c-checkList {
  margin-top: 80px;
  color: v.$gray;
  @include m.sp {
    width: 90%;
    margin: 80px auto 0;
  }
  &_txtArea {
    display: flex;
    align-items: center;
    @include m.sp {
      display: block;
    }
    .ttl {
      display: inline-block;
      background: v.$green;
      @include m.fz(24);
      line-height: 1em;
      color: v.$white;
      font-family: v.$font_yumin;
      padding: 12px 18px;
      @include m.sp {
        display: block;
        text-align: center;
        padding: 18px 0;
      }
    }
    .sub {
      @include m.fz(18);
      font-weight: bold;
      margin-left: 12px;
      @include m.sp {
        display: block;
        line-height: 1.5em;
        padding: 14px 20px;
      }
    }
  }

  &_cont {
    display: block;
    width: 100%;
    border: 2px solid v.$gray;
  }
  tbody {
    display: block;
  }
  &_cmn {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    @include m.sp {
      display: block;
      padding: 0 14px;
    }
    & + .c-checkList_cmn {
      border-top: 2px solid v.$gray;
    }
    &:nth-child(2n) {
      background: v.$green03;
    }
    &_ttl {
      display: block;
      width: 170px;
      font-weight: 400;
      text-align: left;
      // border-right: 2px solid v.$gray;
      padding: 16px 0;
      @include m.sp {
        // width: 134px;
        width: 100%;
      }
      &::before {
        content: "》";
        color: v.$green;
      }
    }
    &_txt {
      display: block;
      width: calc(100% - 170px - 32px);
      box-sizing: border-box;
      padding: 16px;
      border-left: 2px solid v.$gray;
      @include m.sp {
        // width: calc(100% - 134px - 32px);
        display: inline-block;
        width: calc(100% - 42px);
        @include m.fz(14);
        line-height: 1.5em;
        padding: 0 0 16px;
        border-left: none;
      }
    }
    &_btn {
      display: block;
      width: 32px;
      height: 32px;
      border: 2px solid v.$green;
      position: relative;
      @include m.sp {
        display: inline-block;
      }
      input {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: relative;
        z-index: 1;
        & + span::before {
          content: "";
          display: block;
          width: 8px;
          height: 16px;
          background: v.$white;
          position: absolute;
          border-bottom: 3px solid v.$green;
          border-right: 3px solid v.$green;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          opacity: 0;
        }
        &:checked + span::before {
          opacity: 1;
        }
      }
    }
  }
}
