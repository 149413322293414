@use "global/mixin" as m;
@use "global/variable" as v;

.c-mediaTxtArea {
  border-left: 2px solid v.$green;
  padding-left: 20px;
  &_heading {
    @include m.fz(21);
    line-height: 1.5em;
    font-weight: bold;
    color: v.$green;
  }
  &_text {
    color: v.$gray;
    margin-top: 20px;
    padding-left: -1em;
    text-indent: 1em;
  }
}
