@use 'global/mixin' as m;
@use 'global/variable' as v;

.mv {
  width: 100%;
  background: #333;
  position: relative;
  margin-bottom: 40px;
  @include m.tab {
    margin-top: 70px;
  }
  &_en {
    width: 100%;
    @include m.fz(100);
    line-height: 1.3em;
    text-align: center;
    color: v.$white;
    font-family: v.$font_yumin;
    font-weight: 400;
    mix-blend-mode: difference;
    position: absolute;
    bottom: 20px;
    left: 0;
    @include m.sp {
      @include m.fz_w(76);
      line-height: 1.3em;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      &.sp-m {
        @include m.fz_w(60);
        line-height: 1.3em;
      }
    }
  }
  &_ttl {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    @include m.sp {
      width: 100%;
      height: 100%;
      position: relative;
      bottom: auto;
      top: 0;
      left: 0;
      transform: none;
    }
    span {
      display: block;
    }
    &_jp {
      width: 80%;
      min-width: 338px;
      background: v.$white;
      @include m.fz(24);
      line-height: 2.3em;
      color: v.$black;
      margin: 0 auto;
      border-radius: 30px;
      @include m.sp {
        @include m.fz_w(24);
        line-height: 2.3em;
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
      &--large {
        width: 100%;
        min-width: 424px;
      }
      &--larger {
        width: 100%;
        min-width: 522px;

        @include m.sp {
          @include m.fz(16);
        }
      }
      &--largest {
        min-width: 636px;
        width: 100%;

        @include m.sp {
          @include m.fz(16);
        }
      }
    }
  }
  &.type01 {
    height: calc(512 / 1390 * 100vw);
    @include m.sp {
      height: calc(320 / 375 * 100vw);
    }
  }
  &.type02 {
    height: calc(302 / 1390 * 100vw);
    @include m.sp {
      height: calc(320 / 375 * 100vw);
    }
  }
  &.typeTop {
    height: 820px;
    position: relative;
    @include m.sp {
      height: auto;
      background: none;
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 70vh;
      }
    }
    .mv_slider {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include m.sp {
        height: 70vh;
      }
      &_inner {
        @include m.sp {
          height: 70vh;
        }
      }
      &_cmn {
        width: 100%;
        height: 100%;
        position: relative;
        &:nth-of-type(1) {
          background: url(../img/index/mv_bg_01.jpg) center 30% / cover no-repeat;
          @include m.sp {
            background: url(../img/index/mv_bg_01_sp.jpg) center center / cover no-repeat;
          }
        }
        &:nth-of-type(2) {
          background: url(../img/index/mv_bg_02.jpg) center 30% / cover no-repeat;
          @include m.sp {
            background: url(../img/index/mv_bg_02_sp.jpg) center center / cover no-repeat;
          }
        }
      }
    }
    .mv_txt {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      @include m.sp {
        position: relative;
        margin-top: calc(124 / 375 * -100vw);
      }
      &_inner {
        width: 90%;
        max-width: 1024px;
        margin: 0 auto;
        position: relative;
        @include m.sp {
          width: 100%;
        }
      }
      &_chatch {
        width: calc(626 / 1024 * 100%);
        background: v.$white;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 22px 0 32px;
        z-index: 2;
        @include m.sp {
          width: 100%;
          background: none;
          padding: 0 0 32px;
          position: relative;
        }
        &::before {
          content: '';
          display: block;
          width: calc((100vw - 1024px) / 2 + 20px);
          height: 100%;
          background: v.$white;
          position: absolute;
          top: 0;
          right: 10px;
          transform: translateX(100%);
          z-index: -1;
          @include m.screen(0, 1130px) {
            width: calc(5vw + 10px);
            right: 5px;
          }
          @include m.sp {
            display: none;
          }
        }
        &::after {
          content: '';
          display: block;
          width: calc(228 / 626 * 100%);
          height: 100%;
          background: v.$white;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(-50%);
          z-index: -1;
          @include m.sp {
            display: none;
          }
        }
        &_main {
          @include m.fz(48);
          font-family: v.$font_yumin;
          font-weight: 400;
          padding-bottom: 20px;
          border-bottom: 1px solid v.$green;
          @include m.sp {
            width: 90%;
            line-height: 1.4em;
            font-size: calc(40 / 375 * 100vw);
            letter-spacing: calc(5 / 768 * 100vw);
            text-align: center;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            &::before {
              content: '';
              display: block;
              width: 118%;
              padding-top: 160%;
              background: v.$white;
              border-radius: 50vw;
              position: absolute;
              top: -54px;
              left: 50%;
              transform: translateX(-50%);
              z-index: -1;
            }
          }
          span {
            @include m.fz(42);
            @include m.sp {
              @include m.fz(36);
            }
          }
        }
        &_txt {
          @include m.fz(16);
          line-height: 1.8em;
          padding-top: 20px;
          @include m.sp {
            width: 90%;
            position: relative;
            margin: 0 auto;
            z-index: 10;
          }
        }
      }
      &_news {
        width: calc(398 / 1024 * 100%);
        min-height: 112px;
        background: v.$green;
        position: relative;
        z-index: 1;
        @include m.sp {
          width: 100%;
          min-height: 42px;
          z-index: 10;
        }
        &::before {
          content: '';
          display: block;
          width: calc((100vw - 1024px) / 2 + 20px);
          height: 100%;
          background: v.$green;
          position: absolute;
          top: 0;
          left: 10px;
          transform: translateX(-100%);
          z-index: -1;
          @include m.screen(0, 1130px) {
            width: calc(5vw + 10px);
            left: 5px;
          }
          @include m.sp {
            display: none;
          }
        }
        &_inner {
          background: v.$orange;
          min-height: 112px;
          @include m.sp {
            min-height: 42px;
            padding: 20px 10%;
          }
          &::before {
            content: '';
            display: block;
            width: calc((100vw - 1024px) / 2 + 20px);
            height: 100%;
            background: v.$orange;
            position: absolute;
            top: 0;
            left: 10px;
            transform: translateX(-100%);
            z-index: -1;
            @include m.screen(0, 1130px) {
              width: calc(5vw + 10px);
              left: 5px;
            }
            @include m.sp {
              display: none;
            }
          }
        }
        p {
          width: calc(100% - (114 / 398 * 100%));
          @include m.fz(16);
          line-height: 1.8em;
        }
      }
    }
  }
}

.pageAboutUs .mv {
  background: url(../img/aboutus/mv_bg.jpg) center center / cover no-repeat;
  @include m.sp {
    background: url(../img/aboutus/mv_bg_sp.jpg) center 20% / cover no-repeat;
  }
}
.pageCompany .mv {
  background: url(../img/company/mv_bg.jpg) center center / cover no-repeat;
}
.pageBusiness .mv {
  background: url(../img/business/mv_bg.jpg) center center / cover no-repeat;
  @include m.sp {
    background: url(../img/business/mv_bg_sp.jpg) center center / cover no-repeat;
  }
}
.pageSer_ipo .mv {
  background: url(../img/ser_ipo/mv_bg.jpg) center center / cover no-repeat;
}
.pageSer_ma .mv {
  background: url(../img/ser_ma/mv_bg.jpg) center center / cover no-repeat;
}
.pageMaGuideline .mv {
  background: url(../img/ma_guideline/mv_bg.jpg) center center / cover no-repeat;
}
.pageSer_revitalize .mv {
  background: url(../img/ser_revitalize/mv_bg.jpg) center center / cover no-repeat;
}
.pageSer_succession .mv {
  background: url(../img/ser_succession/mv_bg.jpg) center center / cover no-repeat;
  @include m.sp {
    background: url(../img/ser_succession/mv_bg_sp.jpg) center center / cover no-repeat;
  }
}
.pageSer_support .mv {
  background: url(../img/ser_support/mv_bg.jpg) center center / cover no-repeat;
}
.pageSer_tax_advisor .mv {
  background: url(../img/ser_tax_advisor/mv_bg.jpg) center center / cover no-repeat;
}
.pageSer_it .mv {
  background: url(../img/ser_it/mv_bg.jpg) center center / cover no-repeat;
}
.pageSer_coaching .mv {
  background: url(../img/ser_coaching/mv_bg.jpg) center center / cover no-repeat;
}
.pagePrivacypolicy .mv {
  background: url(../img/privacypolicy/mv_bg.jpg) center center / cover no-repeat;
}
.type-mailmagazine .mv {
  background: url(../img/mailmagazine/mv_bg.jpg) center center / cover no-repeat;
}
.type-contact .mv {
  background: url(../img/contact/mv_bg.jpg) center center / cover no-repeat;
}
.type-member .mv {
  background: url(../img/member/mv_bg.jpg) center center / cover no-repeat;
}
.type-news .mv {
  background: url(../img/news/mv_bg.jpg) center center / cover no-repeat;
}
