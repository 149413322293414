@use "global/mixin" as m;
@use "global/variable" as v;

.c-ttlCmn {
  margin-bottom: 38px;
  position: relative;
  z-index: 1;
  &.type02 {
    margin-bottom: 86px;
  }
  span {
    display: inline-block;
    font-family: v.$font_yumin;
    font-weight: 400;
  }
  .en {
    @include m.fz(100);
    line-height: 1em;
    color: v.$black;
    position: relative;
    z-index: 1;
    @include m.sp {
      @include m.fz_w(70);
      line-height: 1em;
    }
  }
  .jp {
    background: v.$green;
    @include m.fz(28);
    line-height: 1.8em;
    color: v.$white;
    padding: 0 52px;
    border-radius: 37px;
    margin-top: 4px;
    &.type02 {
      margin-top: 14px;
    }
    @include m.sp {
      width: 90%;
      @include m.fz_w(28);
      font-weight: 400;
      padding: 0;
      &.sp-max {
        width: 100%;
        border-radius: 0;
        padding: 12px 0;
      }
    }
  }
  @include m.sp {
    text-align: center;
  }
  &.right {
    text-align: right;
    @include m.sp {
      text-align: center;
    }
  }
  &.center {
    text-align: center;
    @include m.sp {
      text-align: center;
    }
  }
  &.long {
    // longのときは<br>取るべし
    width: 100%;
    max-width: 850px;
    text-align: center;
    margin: 0 auto 38px;
    span {
      display: block;
    }
  }
  &.white {
    .en {
      color: v.$white;
    }
  }
  &.sp-black {
    @include m.sp {
      .en {
        color: v.$black;
      }
    }
  }
}
