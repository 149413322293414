@use "global/mixin" as m;
@use "global/variable" as v;

.c-supportList {
  margin-top: 60px;
  color: v.$black;
  &_cont {
    display: flex;
    border: 2px solid v.$gray;
    @include m.sp {
      display: block;
    }
    & + .c-supportList_cont {
      margin-top: 50px;
    }
  }
  &_ttl {
    display: block;
    width: 130px;
    background: v.$green;
    position: relative;
    @include m.sp {
      width: 100%;
      padding-top: 10px;
    }
    tr {
      padding: 0 28px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @include m.sp {
        display: block;
        text-align: center;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
      th {
        @include m.fz(36);
        line-height: 1.5em;
        font-weight: 400;
        font-family: v.$font_yumin;
        text-align: center;
        color: v.$white;
        @include m.sp {
          display: block;
          width: 100%;
        }
        &::after {
          content: "サポート";
          display: block;
          @include m.fz(16);
          line-height: 1em;
          font-weight: bold;
          font-family: v.$font_noto;
          border-top: 1px solid v.$white;
          padding-top: 12px;
          margin-top: 10px;
          @include m.sp {
            display: block;
            width: 5em;
            @include m.fz(14);
            line-height: 1em;
            margin: 10px auto 0;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
  &_list {
    display: block;
    width: calc(100% - 130px);
    background: v.$white;
    @include m.sp {
      width: 100%;
    }
    tr {
      background: v.$light-gray;
      display: flex;
      align-items: center;
      @include m.sp {
        display: block;
        & + tr {
          border-top: 1px solid v.$black;
        }
      }
      &:nth-child(2n) {
        background: v.$white;
      }
      th {
        display: flex;
        align-items: center;
        width: 242px;
        height: 100%;
        font-weight: 400;
        text-align: left;
        box-sizing: border-box;
        padding: 10px 0;
        padding-left: 18px;
        @include m.sp {
          width: 100%;
          padding: 12px 10%;
        }
        &::before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent transparent transparent v.$black;
          border-width: 8px 0 8px 10px;
          margin-right: 10px;
        }
      }
      td {
        border-left: 1px solid v.$gray;
        display: block;
        width: calc(100% - 242px);
        box-sizing: border-box;
        padding: 10px 0;
        padding-left: 30px;
        @include m.sp {
          width: 100%;
          padding: 0 10% 12px;
          border-left: none;
        }
      }
    }
  }
}
