@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-media {
  display: flex;
  justify-content: space-between;
  @include m.sp {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
  @include m.sp {
    display: block;
  }

  & + .c-media {
    margin-top: 80px;
    @include m.sp {
      margin: 40px auto 0;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }
  &--re {
    @include m.sp {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  & + & {
    margin: 80px 0 0;

    @include m.sp {
      margin: 40px auto 0;
    }
  }

  & + &Cont {
    margin: 80px 0 0;

    @include m.sp {
      margin: 40px auto 0;
    }
  }

  &Cont + &Cont {
    margin: 80px 0 0;

    @include m.sp {
      margin: 40px auto 0;
    }
  }

  &_box {
    @include m.pc {
      flex: 1;
    }
    & + & {
      @include m.sp {
        margin-top: 50px;
      }
    }

    &--left {
      @include m.pc {
        width: 40%;
        margin: 0 50px 0 0;
      }
    }

    &--right {
      @include m.pc {
        width: 40%;
        margin: 0 0 0 50px;
      }
    }

    & + .c-media_table {
      margin: 80px 0 0;

      @include m.sp {
        margin: 40px auto 0;
      }

      &.c-media_table--sm {
        margin-top: 0;
        @include m.sp {
          margin: 40px auto 0;
        }
      }
    }
  }

  &_pic {
    @include m.sp {
      margin: 40px auto 0;
    }

    &--ma {
      width: 49%;

      @include m.pc {
        max-width: 500px;
      }

      @include m.sp {
        width: 100%;
      }

      &--02 {
        width: 45%;
        @include m.pc {
          max-width: 420px;
        }

        @include m.sp {
          width: 100%;
        }
      }
    }

    &_ttl {
      text-align: center;
      color: v.$green;
      border: 1px solid v.$green;
      padding: 14px 0;
      margin-bottom: 20px;
    }
  }

  &_table {
    width: 65%;

    @include m.pc {
      max-width: 660px;
    }

    @include m.sp {
      width: 100%;
      margin-top: 30px;
    }
    &--sm {
      width: 48%;
      @include m.sp {
        width: 100%;
      }
    }
    &--ma {
      width: 100%;
      margin-top: 60px;
      @include m.pc {
        max-width: none;
      }
    }

    & + .c-intro_text {
      margin-top: 50px;
    }
  }

  &Cont {
    width: 100%;
    @include m.sp {
      width: 90%;
      margin: 0 auto;
    }
    & + .c-media {
      margin: 80px 0 0;

      @include m.sp {
        margin: 40px auto 0;
      }
    }
  }
}
