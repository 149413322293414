@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-ta {
  &-ce {
    text-align: center;
  }

  &-ri {
    text-align: right;
  }
}
