@use "global/mixin" as m;
@use "global/variable" as v;

.pageBusiness {
  .lineup {
    @include m.sp {
      .c-ttlCmn_underTxt {
        width: 90%;
        margin: 0 auto;
        color: v.$gray;
        position: relative;
        z-index: 10;
      }
    }
    &_figArea {
      width: 90vw;
      height: 90vw;
      max-width: 484px;
      max-height: 484px;
      // background: rgba(164, 203, 98, .25);
      border-radius: 50%;
      border: 32px solid rgba(164, 203, 98, 0.37);
      margin: 66px auto 0;
      position: relative;
      @include m.sp {
        width: 100%;
        height: auto;
        max-width: none;
        max-height: none;
        background: v.$green03;
        border-radius: 0;
        border: none;
        margin: 60px 0 0;
        padding-top: 100px;
      }
      &_inner {
        display: block;
        width: calc(269 / 484 * 100%);
        height: calc(269 / 484 * 100%);
        background: v.$green;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: 0.5s all ease;
        @include m.sp {
          position: static;
          width: 100%;
          height: auto;
          background: none;
          border-radius: 0;
          transform: none;
          &::after {
            content: "";
            display: block;
            width: 90vw;
            height: 0;
            padding-top: 90%;
            background: v.$white;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -80%);
            z-index: 1;
          }
        }
        &::before {
          content: "";
          display: block;
          width: calc(135 / 269 * 100%);
          height: calc(135 / 269 * 100%);
          background: url(../img/business/service_logo.png) center center /
            cover no-repeat;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          @include m.sp {
            width: 96px;
            height: 96px;
            top: 0;
          }
        }
      }
      &_cmn {
        position: absolute;
        @include m.sp {
          position: static;
          border-bottom: 1px solid v.$gray;
        }
        a {
          display: flex;
          align-items: center;
          @include m.sp {
            width: 90%;
            margin: 0 auto;
            &::after {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              border-top: 2px solid v.$black;
              border-right: 2px solid v.$black;
              transform: rotate(45deg);
              margin-right: 0;
              margin-left: auto;
            }
          }
        }
        img {
          display: inline-block;
          width: 68px;
        }
        &_txt {
          display: inline-block;
          @include m.fz(20);
          line-height: 1.3em;
          font-weight: bold;
          position: relative;
          @include m.sp {
            margin-left: 16px;
          }
        }
        &:nth-child(2) a,
        &:nth-child(4) a,
        &:nth-child(5) a {
          flex-direction: row-reverse;
          @include m.sp {
            flex-direction: row;
          }
        }
        &:nth-child(1) {
          top: 0;
          right: 0;
          transform: translate(58%, -38%);
          // transform: translate(33%, -59%);
          @include m.sp {
            transform: none;
          }
        }
        &:nth-child(3) {
          top: 50%;
          right: 0;
          transform: translate(88%, -50%);
          // transform: translate(88%, -8%);
          @include m.sp {
            transform: none;
          }
        }
        &:nth-child(6) {
          bottom: 0;
          right: 0;
          transform: translate(58%, 38%);
          @include m.sp {
            transform: none;
          }
        }
        &:nth-child(4) {
          bottom: 0;
          left: 0;
          transform: translate(-58%, 38%);
          @include m.sp {
            transform: none;
          }
        }
        &:nth-child(2) {
          top: 50%;
          left: 0;
          transform: translate(-88%, -50%);
          // transform: translate(-88%, -8%);
          @include m.sp {
            transform: none;
          }
        }
        &:nth-child(5) {
          top: 0;
          left: 0;
          transform: translate(-58%, -38%);
          // transform: translate(-76%, 26%);
          @include m.sp {
            transform: none;
          }
        }
        // &:nth-child(6){
        //   top: 0;
        //   right: 0;
        //   transform: translate(-58%, -38%);
        //   // transform: translate(76%, 26%);
        //   @include m.sp {
        //     transform: none;
        //   }
        // }
      }
      &.lineupAnimation {
        background: v.$white;
        transition: 0.5s all ease;
        .lineup_figArea_cmn {
          &_txt {
            position: relative;
            opacity: 0;
            transition: 0.5s all ease;
          }
          &:nth-child(1) .lineup_figArea_cmn_txt,
          &:nth-child(3) .lineup_figArea_cmn_txt,
          &:nth-child(6) .lineup_figArea_cmn_txt {
            left: -100px;
          }
          &:nth-child(2) .lineup_figArea_cmn_txt,
          &:nth-child(4) .lineup_figArea_cmn_txt,
          &:nth-child(5) .lineup_figArea_cmn_txt {
            right: -100px;
            @include m.sp {
              right: auto;
              left: -100px;
            }
          }
        }
        &.animated {
          background: rgba(164, 203, 98, 0.25);
          .lineup_figArea_cmn {
            &_txt {
              position: relative;
              opacity: 1;
            }
            &:nth-child(1) .lineup_figArea_cmn_txt,
            &:nth-child(3) .lineup_figArea_cmn_txt,
            &:nth-child(6) .lineup_figArea_cmn_txt {
              left: 0;
            }
            &:nth-child(2) .lineup_figArea_cmn_txt,
            &:nth-child(4) .lineup_figArea_cmn_txt,
            &:nth-child(5) .lineup_figArea_cmn_txt {
              right: 0;
              @include m.sp {
                right: auto;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  .growth {
    &_fig {
      margin-top: 80px;
      @include m.sp {
        width: 90%;
        margin: 40px auto 0;
      }
    }
    @include m.sp {
      .c-ttlCmn_underTxt {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}
