@use "global/mixin" as m;
@use "global/variable" as v;

.btn {
  &--white {
    border: 1px solid #a4cb62;
    color: #a4cb62;
    position: relative;
    padding: 0.5rem;
    max-width: 200px;
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
    transition: 0.3s ease color, 0.3s ease background-color;
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      border-top: 2px solid #a4cb62;
      border-right: 2px solid #a4cb62;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: rotate(45deg) translateY(-50%);
      transform-origin: center top;
      transition: 0.3s ease border-color;
    }
    @include m.pc {
      &:hover {
        color: #fff;
        background-color: #a4cb62;
        &::after {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
      }
    }
  }
}

.btnRight {
  a {
    display: block;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border-top: 3px solid v.$green;
      border-right: 3px solid v.$green;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: rotate(45deg) translateY(-50%);
      transform-origin: center top;
    }
  }
}

.btn {
  &-green {
    display: block;
    width: 80%;
    max-width: 600px;
    background: linear-gradient(to bottom, #a4cb62, #beeb73);
    margin: 70px auto 0;
    a {
      display: block;
      @include m.fz(24);
      font-weight: bold;
      text-align: center;
      color: v.$white;
      padding: 20px 0;
    }
  }
  &-greenBtn {
    display: block;
    width: 80%;
    max-width: 600px;
    background: linear-gradient(to bottom, #a4cb62, #beeb73);
    margin: 70px auto 0;
    display: block;
    @include m.fz(24);
    font-weight: bold;
    text-align: center;
    color: v.$white;
    padding: 20px 0;
    border: none;
  }
  &-blackBtn {
    display: block;
    width: 80%;
    max-width: 600px;
    background: v.$black;
    margin: 70px auto 0;
    display: block;
    @include m.fz(24);
    font-weight: bold;
    text-align: center;
    color: v.$white;
    padding: 20px 0;
    border: none;
  }
  &area-two {
    width: 90%;
    max-width: 734px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    & > * {
      width: 45%;
    }
  }
}

.btnCmn {
  &-long {
    width: 240px;
    background: v.$white;
    border: 1px solid v.$green;
    margin-top: 50px;
    margin-left: auto;
    margin-right: 0;
    transition: 0.3s all ease;
    @include m.sp {
      width: 70%;
      max-width: 240px;
      margin: 40px auto 0;
    }
    a {
      @include m.fz(18);
      font-weight: bold;
      text-align: center;
      color: v.$green;
      padding: 26px 0;
      transition: 0.3s all ease;
    }
    &:hover {
      width: 100%;
      background: v.$green;
      a {
        color: v.$white;
        &::after {
          border-top: 3px solid v.$white;
          border-right: 3px solid v.$white;
        }
      }
    }
    &.black {
      background: v.$green;
      border: none;
      a {
        color: v.$white;
        &::after {
          border-top: 3px solid v.$white;
          border-right: 3px solid v.$white;
        }
      }
      &:hover {
        background: v.$black;
      }
    }
    &.white {
      background: v.$green;
      border: none;
      a {
        color: v.$white;
        &::after {
          border-top: 3px solid v.$white;
          border-right: 3px solid v.$white;
        }
      }
      &:hover {
        background: v.$white;
        a {
          color: v.$green;
          &::after {
            border-top: 3px solid v.$green;
            border-right: 3px solid v.$green;
          }
        }
      }
    }
  }
}
