@use "global/mixin" as m;
@use "global/variable" as v;

.c-table {
  width: 100%;

  &_cont {
    width: 100%;
    table-layout: fixed;
    border: 1px solid v.$black;

    thead {
      width: 100%;
      display: inline-table;
      th {
        padding: 16px 0;
        @include m.fz(19);
        text-align: center;
        vertical-align: middle;
        color: v.$white;
        background-color: v.$green;

        @include m.sp {
          @include m.fz(15);
        }

        & + th {
          border-left: 1px solid v.$black;
        }

        &.left {
          text-align: left;
          padding-left: 16px;
        }
      }
    }

    tbody {
      width: 100%;
      display: inline-table;
      tr {
        & + tr {
          border-top: 1px solid v.$black;
        }
      }

      td {
        padding: 16px;
        @include m.fz(16);
        line-height: 1.4;
        vertical-align: middle;
        color: v.$black;

        @include m.sp {
          padding: 16px 8px;
          @include m.fz(14);
          line-height: 1.4;
        }

        & + td {
          border-left: 1px solid v.$black;
        }

        span {
          display: block;
          padding-left: 1em;
          text-indent: -1em;
        }
      }
    }
  }

  &-sp {
    tbody {
      display: block;
      width: 100%;
    }
    tr {
      display: flex;
      width: 100%;
      border: 1px solid v.$black;
      & + tr {
        margin-top: 20px;
      }
    }
    th {
      display: block;
      width: 90px;
      background: v.$green;
      color: v.$white;
      padding: 20px 0;
      span {
        display: block;
        margin-top: 30px;
      }
    }
    td {
      display: block;
      width: calc(100% - 90px);
      padding: 20px;
      border-left: 1px solid v.$black;
      .cont {
        & + .cont {
          margin-top: 18px;
        }
        p {
          color: v.$green;
        }
      }
    }
  }

  &_box {
    &--w18 {
      width: 18%;
    }
    &--w20 {
      width: 20%;
      @include m.sp {
        width: 30%;
      }
    }
    &--w24 {
      width: 24%;
    }
    &--w25 {
      width: 28%;
    }

    &--w40 {
      width: 40%;
    }
    &--w50 {
      width: 50%;
    }
    &--w80 {
      width: 80%;
      @include m.sp {
        width: 70%;
      }
    }
    &--w82 {
      width: 82%;
    }
  }

  &_bold {
    font-weight: bold;
  }

  &_center {
    text-align: center;
  }
}
