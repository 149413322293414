@use "global/mixin" as m;
@use "global/variable" as v;

.c-heading {
  margin: 0 0 44px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @include m.sp {
    margin: 0 0 60px;
  }

  &_category {
    padding: 0 0 8px;
    font-family: v.$font_yumin;
    @include m.fz(19);
    font-family: 500;
    line-height: 1.6;
    color: v.$green;

    @include m.sp {
      padding: 0 0 24px;
    }
  }

  &_heading {
    padding: 0 0 16px;
    font-family: v.$font_yumin;
    @include m.fz(32);
    font-family: 600;
    line-height: 1.6;
    letter-spacing: 0.02em;

    @include m.sp {
      text-align: center;
      line-height: 1.6;
    }
  }

  &_lead {
    padding: 16px 62px;
    display: inline-block;
    font-family: v.$font_yumin;
    @include m.fz(28);
    font-weight: 600;
    letter-spacing: 0.02em;
    color: v.$green;
    background-color: v.$black;

    @include m.sp {
      width: 100%;
      padding: 12px 0;
      text-align: center;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .c-heading * {
    font-family: serif;
  }
}
