@use 'global/mixin' as m;
@use 'global/variable' as v;

.pageMaGuideline {
  .secCmn_inner {
    width: 90%;
    margin: 0 auto;
  }

  .text {
    @include m.fz(20);
  }

  h3 {
    margin-top: 20px;
  }
  p {
    @include m.fz(16);
    line-height: 1.5em;
    text-indent: 1em;
    margin-top: 32px;
  }
  ol {
    padding-left: 2em;
    text-indent: -1em;
    li {
      margin-top: 4px;
    }
  }
}
