@use "global/mixin" as m;
@use "global/variable" as v;

.l-wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @include m.pc {
    max-width: 1064px;
  }

  @include m.sp {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
}
