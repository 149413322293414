@use "global/mixin" as m;
@use "global/variable" as v;

.globalFooter {
  width: 100%;
  background: v.$white;
  margin-top: 50px;
  a {
    color: #000;
  }
  &_inner {
    max-width: 1024px;
    width: 90%;
    margin: 0 auto;
    padding: 70px 0 60px;
    position: relative;
    @include m.sp {
      width: 100%;
      padding: 0;
    }
  }
  &_logoArea {
    &_logo {
      width: 80%;
      max-width: 342px;
      @include m.sp {
        width: 70%;
        margin: 0 auto;
        padding: 37px 0;
      }
    }
    .pageTopBtn {
      width: 30%;
      max-width: 132px;
      height: calc(79 / 1024 * 100%);
      background: v.$black;
      position: absolute;
      top: 0;
      right: 0;
      @include m.sp {
        width: 100%;
        height: calc(80 / 375 * 100vw);
        max-width: none;
        position: relative;
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border-top: 3px solid v.$white;
          border-left: 3px solid v.$white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: rotate(45deg) translate(-50%, -30%);
          transform-origin: left top;
        }
      }
    }
  }

  &_main {
    display: flex;
    justify-content: space-between;
    margin-top: 82px;
    @include m.sp {
      display: none;
    }
  }

  &_accessArea {
    width: calc(100% - 578px - 5%);
    &_office {
      & + .globalFooter_accessArea_office {
        margin-top: 28px;
      }
      &_access {
        line-height: 1.5em;
      }
      &_tel {
        margin-top: 14px;
        a {
          display: flex;
          align-items: center;
          &::before {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url(../img/common/icon_tel.svg) center center / cover
              no-repeat;
            border-radius: 50%;
          }
        }
        &_inner {
          line-height: 1em;
          margin-left: 10px;
          span {
            display: inline-block;
            width: 100%;
            &.time {
              @include m.fz(12);
            }
          }
        }
      }
    }
    &_contact {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 12px;
      &_cmn {
        margin-top: 16px;
        a {
          display: block;
          @include m.fz(14);
          font-weight: bold;
          text-align: center;
          padding: 30px 0;
        }
        &.mail {
          width: 53%;
          min-width: 165px;
          background: v.$green;
          a {
            color: v.$white;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
              content: "";
              display: inline-block;
              width: 20px;
              height: 16px;
              background: url(../img/common/icon_mail.svg) center center / cover
                no-repeat;
              margin-right: 14px;
            }
          }
        }
        &.recruit {
          width: 42%;
          min-width: 130px;
          background: v.$white;
          border: 1px solid v.$green;
          a {
            color: v.$green;
            position: relative;
            &::after {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              box-sizing: border-box;
              border-top: 2px solid v.$green;
              border-right: 2px solid v.$green;
              position: absolute;
              top: 50%;
              right: 20px;
              transform: rotate(45deg) translateY(-50%);
              transform-origin: center top;
            }
          }
        }
      }
    }
  }

  &_list {
    width: 578px;
    display: flex;
    justify-content: space-between;
    &_inner {
      width: 132px;
      &.type01 {
        width: 188px;
        position: relative;
      }
    }
    &_cmn {
      width: 150px;
      & + .globalFooter_list_cmn {
        margin-top: 50px;
      }
      &_name {
        display: block;
        @include m.fz(16);
        line-height: 1.3em;
        font-weight: bold;
        color: v.$green;
        border-bottom: 1px solid v.$green;
      }
      &_inner {
        margin-top: 14px;
        &_cmn {
          & + .globalFooter_list_cmn_inner_cmn {
            margin-top: 18px;
          }
          > a {
            display: block;
            @include m.fz(14);
            line-height: 1.5em;
            position: relative;
            padding-left: 20px;
            &::before {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              box-sizing: border-box;
              border-top: 2px solid v.$green;
              border-right: 2px solid v.$green;
              position: absolute;
              top: 50%;
              left: 0;
              transform: rotate(45deg) translateY(-50%);
              transform-origin: center top;
            }
          }
        }
        &_list {
          @include m.fz(14);
          margin-top: 18px;
          &_cmn {
            margin-top: 14px;
            a {
              @include m.fz(14);
              line-height: 1em;
              padding-left: calc(20px);
              &::before {
                content: "・";
              }
            }
          }
        }
      }
    }
    &_sub {
      position: absolute;
      bottom: 0;
      left: 0;
      a {
        @include m.fz(12);
        border-bottom: 1px solid v.$black;
        & + a {
          margin-left: 14px;
        }
      }
    }
  }

  &_btm {
    background: v.$green;
    padding: 10px 0;
    p {
      @include m.fz(12);
      line-height: 1.5em;
      color: v.$black;
      text-align: center;
    }
  }
}
