@use "global/mixin" as m;
@use "global/variable" as v;

.c-number {
  counter-reset: num 0;
  &_cmn {
    counter-increment: num 1;
    @include m.sp {
      display: block;
    }
    & + .c-number_cmn {
      margin-top: 50px;
    }
    * {
      color: v.$black02;
    }
    &_txtArea {
      width: 55%;
      @include m.sp {
        width: 90%;
        margin: 30px auto 0;
      }
      &_ttl {
        display: flex;
        align-items: center;
        position: relative;
        @include m.sp {
          padding-left: 0;
        }
        span {
          @include m.fz(20);
          line-height: 1.5em;
          font-weight: bold;
          margin-left: 10px;
          flex: 1;
          @include m.sp {
            font-size: 1.6rem;
            margin-left: 16px;
          }
        }
        &::before {
          display: inline-block;
          content: counter(num);
          width: 47px;
          height: 47px;
          background: v.$green;
          @include m.fz(20);
          line-height: 47px;
          font-family: v.$font_futura;
          text-align: center;
          color: v.$white;
          border-radius: 50%;
          @include m.sp {
            width: 40px;
            height: 40px;
            @include m.fz(16);
            line-height: 40px;
          }
        }
      }
    }
  }
}
