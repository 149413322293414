@use "global/mixin" as m;
@use "global/variable" as v;

.global--nav {
  width: 100%;
  background: rgba(255, 255, 255, 0.45);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  @include m.tab {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }
  &::before {
    content: "";
    display: block;
    width: calc((100% - 1024px) / 2);
    height: 100%;
    background: v.$white;
    position: absolute;
    top: 0;
    left: 0;
    @include m.screen(800px, 1130px) {
      width: 5%;
    }
    @include m.tab {
      display: none;
    }
  }
  &_inner {
    width: 95%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include m.tab {
      width: 100%;
      height: 70px;
      display: block;
      background: v.$white;
    }
  }

  &_main {
    width: calc(347 / 1024 * 100%);
    background: v.$white;
    padding: 44px 65px 44px 0;
    box-sizing: border-box;
    border-top-right-radius: 70px;
    border-bottom-right-radius: 70px;
    @include m.tab {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 20px 0;
      margin: 0 auto;
      .global--nav_logo {
        height: 100%;
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
  &_btn {
    width: 25px;
    height: 22px;
    position: relative;
    span {
      width: 100%;
      height: 1px;
      background: v.$black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s all ease;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: v.$black;
      position: absolute;
      left: 50%;
      transition: 0.3s all ease;
    }
    &::before {
      top: 0;
      transform: translateX(-50%) rotate(0);
    }
    &::after {
      bottom: 0;
      transform: translateX(-50%) rotate(0);
    }
  }

  &_list {
    width: calc(650 / 1024 * 100%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 22px;
    @include m.tab {
      display: none;
      width: 100%;
      max-height: calc(100vh - (70 / 375 * 100vw));
      background: v.$white;
      overflow-y: auto;
      padding-top: 0;
    }
    &_sub {
      display: flex;
      justify-content: flex-end;
      @include m.tab {
        display: block;
        width: 70%;
        padding: 30px 0;
        margin: 0 auto;
      }
      &_cmn {
        @include m.tab {
          position: relative;
          padding: 14px 0;
          & + .global--nav_list_sub_cmn {
            margin-top: 16px;
          }
          &--recruit {
            width: 100%;
            border: 1px solid v.$green;
            margin-top: 16px;
            &.bordernone {
              border: none;
            }
            a {
              display: block;
              @include m.fz(14);
              color: v.$green;
              text-align: center;
              padding: 18px 0;
              &::after {
                width: 10px;
                height: 10px;
                border-top: 2px solid v.$green;
                border-right: 2px solid v.$green;
              }
            }
          }
        }
        &:nth-child(1) {
          background: v.$green;
          img {
            width: 20px;
            height: 16px;
          }
        }
        &:nth-child(2) {
          background: v.$orange;
          img {
            width: 16px;
            height: 23px;
          }
        }
        .icon {
          display: inline-block;
          width: 50px;
          height: 50px;
          position: relative;
          @include m.tab {
            position: absolute;
            top: 50%;
            left: 40px;
            transform: translateY(-50%);
          }
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .txt {
          display: inline-block;
          @include m.fz(16);
          font-weight: bold;
          text-align: center;
          color: v.$white;
          line-height: 48px;
          padding-right: 0;
          width: 0;
          height: 48px;
          overflow: hidden;
          box-sizing: border-box;
          transition: 0.2s all ease;
          @include m.tab {
            display: block;
            width: auto;
            text-align: center;
          }
        }
        a {
          display: block;
          line-height: 1em;
          @include m.mintab {
            &:hover .txt {
              width: 164px;
              padding-right: 20px;
              &:nth-child(2) {
                width: 132px;
              }
            }
          }
        }
      }
    }

    &_inner {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;
      @include m.tab {
        display: block;
      }
      a,
      span {
        @include m.fz(16);
        font-weight: bold;
        color: v.$black;
        @include m.tab {
          color: v.$white;
        }
      }
    }
    &_cmn {
      width: 20%;
      cursor: pointer;
      transition: 0.3s all;
      @include m.tab {
        width: 100%;
        border-top: 1px solid v.$white;
        background: v.$green;
        transition: none;
        &.navDown {
          .global--nav_list_cmn_inner {
            position: relative;
            &::before,
            &::after {
              content: "";
              display: block;
              width: 20px;
              height: 1px;
              background: v.$white;
              position: absolute;
              top: 50%;
              right: 24px;
              transform: translateY(-50%);
            }
            &::before {
              transform: translateY(-50%) rotate(90deg);
            }
          }
        }
      }
      &:hover {
        background: v.$green;
      }
      &_inner {
        display: block;
        width: 100%;
        text-align: center;
        padding: 14px 0;
        @include m.tab {
          padding: 24px 0;
        }
      }
      &_list {
        width: 100%;
        background: v.$white;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 34px;
        left: 0;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s all ease;
        transition: none;
        @include m.tab {
          display: none;
          position: relative;
          background: v.$green;
          top: 0;
          padding: 0;
          opacity: 1;
          visibility: visible;
        }
        &_cmn {
          margin-right: 40px;
          margin-top: 20px;
          @include m.tab {
            margin: 0;
            margin-top: 1px;
            background: v.$white;
          }
          a {
            font-weight: 400;
            position: relative;
            @include m.tab {
              display: block;
              text-align: center;
              color: v.$black;
              padding: 24px 0;
            }
            &::before {
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              box-sizing: border-box;
              border-top: 2px solid v.$green;
              border-right: 2px solid v.$green;
              transform: rotate(45deg);
              margin-right: 8px;
              @include m.tab {
                border-top: 1px solid v.$black;
                border-right: 1px solid v.$black;
                position: absolute;
                top: 50%;
                right: 24px;
                transform: translateY(-50%) rotate(45deg);
              }
            }
          }
          &_inner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;
            @include m.sp {
              display: none;
            }
            &_cmn {
              & + & {
                margin-left: 30px;
              }
              &::before {
                content: "・";
                color: v.$green;
              }
              a {
                font-weight: 400;
                position: relative;
                @include m.tab {
                  display: block;
                  text-align: center;
                  color: v.$black;
                  padding: 24px 0;
                }
              }
            }
          }
        }
      }
      &:hover {
        .global--nav_list_cmn_list {
          top: 44px;
          opacity: 1;
          visibility: visible;
          @include m.tab {
            top: 0;
          }
        }
      }
    }
  }
  &.open {
    .global--nav_btn {
      span {
        width: 0;
      }
      &::before {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        bottom: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.active {
    background: v.$white;
    position: fixed;
    .global--nav_main {
      width: calc(206 / 1024 * 100%);
      padding: 20px 0;
    }
    .global--nav_inner {
      align-items: center;
    }
    .global--nav_list {
      width: calc(775 / 1024 * 100%);
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 20px 0;
      &_inner {
        width: calc(650 / 780 * 100%);
      }
      &_sub {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        &_cmn .icon {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
