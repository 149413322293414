@use "global/mixin" as m;
@use "global/variable" as v;

.pageCompany {
  .outline {
    &_table {
      display: block;
      width: 100%;
      border: 1px solid v.$black;
      @include m.sp {
        width: 90%;
        margin: 0 auto;
      }
      &_inner {
        display: block;
        width: 100%;
      }
      tr {
        display: block;
        background: #f8ffed;
        display: flex;
        & + tr {
          border-top: 1px solid v.$black;
        }
        &:nth-child(2n) {
          background: v.$white;
        }
      }
      th {
        width: 212px;
        font-weight: 400;
        padding: 16px 0;
        @include m.sp {
          width: 90px;
        }
      }
      td {
        width: calc(100% - 212px);
        border-left: 1px solid v.$black;
        padding: 16px 36px;
        @include m.sp {
          width: calc(100% - 90px);
          padding: 16px 16px;
        }
        p + p {
          margin-top: 12px;
        }
      }
    }
  }

  .history {
    &_table {
      display: block;
      width: 100%;
      @include m.sp {
        width: 90%;
        margin: 0 auto;
      }
      &_inner {
        display: block;
        width: 100%;
      }
      tr {
        display: block;
        display: flex;
        & + tr {
          border-top: 1px solid v.$black;
        }
      }
      th {
        width: 170px;
        font-weight: 400;
        padding: 16px 0;
        padding-left: 82px;
        @include m.sp {
          width: 88px;
          padding-left: 0;
        }
      }
      td {
        width: calc(100% - 160px);
        padding: 16px 40px;
        @include m.sp {
          width: calc(100% - 88px);
          padding: 16px 0 16px 30px;
        }
      }
    }
  }

  .office {
    &_cmn {
      & + .office_cmn {
        margin-top: 46px;
      }
      &_name {
        color: v.$green;
        padding-left: 50px;
        border-bottom: 1px solid v.$black;
        @include m.sp {
          text-align: center;
          padding-left: 0;
        }
      }
      &_detail {
        padding: 0 50px;
        display: flex;
        justify-content: space-between;
        margin-top: 33px;
        @include m.sp {
          display: block;
          padding: 0;
        }
        &_domicile,
        &_access {
          width: 47%;
          @include m.sp {
            width: 100%;
          }
        }

        &_access {
          .accessTxt::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 1px solid v.$green;
            transform: rotate(45deg);
            margin-right: 4px;
          }
        }

        &_map {
          width: 100%;
          height: 0;
          padding-top: calc(293 / 429 * 100%);
          position: relative;
          @include m.sp {
            margin-top: 20px;
          }
          iframe {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
        &_txtArea {
          margin-top: 25px;
          @include m.sp {
            padding: 0 20px;
          }
          * {
            color: v.$black02;
          }
        }
      }
    }
  }
}
