@use "global/mixin" as m;
@use "global/variable" as v;

.scrollHint {
  &-1000 {
    width: 100rem;

    @include m.sp {
      width: 80rem;
    }
  }

  &-900 {
    width: 90rem;

    @include m.sp {
      width: 70rem;
    }
  }
}
