@use "global/mixin" as m;
@use "global/variable" as v;

.p-ser {
  // common
  &_subTtl {
    @include m.fz(20);
    font-family: v.$font_yumin;
    line-height: calc(44 / 20);
    text-align: center;
    color: v.$black;
    background-color: v.$white;
    padding: 1rem;

    @include m.sp {
      font-size: 1.6rem;
    }

    // FIXME: ITページ編集時にクラスを変える
    &--large {
      @include m.fz(24);
      line-height: calc(40 / 24);

      @include m.sp {
        font-size: 2rem;
      }
    }
  }
}
