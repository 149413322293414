@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 0 through 20 {
  .u-mt-#{$i} {
    margin-top: #{$i}rem;

    @include m.sp {
      margin-top: calc(#{$i}rem/ 2);
    }
  }
}
