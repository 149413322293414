@use "global/mixin" as m;
@use "global/variable" as v;

.p-coaching {
  &_bg {
    background: v.$green04;
    padding: 80px 0;
    @include m.sp {
      padding: 30px 0;
    }
  }

  &_figure {
    width: 100%;
    margin-top: 64px;

    @include m.sp {
      margin-top: 3.2rem;
    }
  }

  &_arrow {
    @include m.sp {
      max-width: 6rem;
    }
  }

  &_scroll {
    margin-top: 66px;
  }

  &_gallery {
    gap: 0 10px;
    margin-top: 48px;

    @include m.sp {
      margin-top: 2.4rem;
    }

    &--wide {
      gap: 0 32px;
      margin-top: 66px;

      @include m.sp {
        margin-top: 3.3rem;
      }
    }

    &Item {
      @include m.pc {
        width: calc((100% - 10px * (3 - 1)) / 3);
      }

      &--wide {
        @include m.pc {
          width: calc((100% - 32px * (3 - 1)) / 3);
        }
      }

      & + & {
        @include m.sp {
          margin-top: 2rem;
        }
      }
    }
  }

  &_container {
    display: flex;
    justify-content: center;
    gap: 32px;

    @include m.sp {
      display: block;

      & + & {
        margin-top: 2rem;
      }
    }
  }

  &_card {
    display: flex;
    width: 496px;

    @include m.sp {
      display: block;
      max-width: 22.5rem;
      width: 100%;
      margin: 0 auto;
    }

    &Title {
      @include m.fz(20);

      @include m.sp {
        @include m.fz_w(20);
      }
    }

    &Image {
      max-width: 225px;
      width: 100%;

      @include m.sp {
        max-width: 22.5rem;
      }
    }

    &Caption {
      background-color: #fff;
      padding: 24px;
      flex: 1;

      @include m.sp {
        padding: 1.2rem 1.6rem;
      }
    }

    &Text {
      font-size: 16px;
      line-height: 1.5;
      color: v.$black02;

      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }
}
