@use "global/mixin" as m;
@use "global/variable" as v;

.formCmn {
  * {
    @include m.fz(16);
  }

  &_cmn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 64px 16px 20px;
    box-sizing: border-box;
    position: relative;
    @include m.sp {
      display: block;
      padding: 10px;
    }
    & + .formCmn_cmn {
      border-top: 1px solid v.$green;
    }
    &_inner {
      position: relative;
      .error {
        min-width: 125px;
        background: v.$orange;
        @include m.fz(12);
        font-size: 1.2rem !important;
        text-align: center;
        color: v.$white !important;
        border-radius: 26px;
        padding: 12px 26px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -80%);
        &::before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: v.$orange transparent transparent transparent;
          border-width: 9px 10px 0 10px;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    label {
      width: 35%;
      @include m.fz(16);
      line-height: 1.5em;
      @include m.sp {
        display: block;
        width: 100%;
        margin-bottom: 6px;
      }
      &.required {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include m.sp {
          justify-content: flex-start;
        }
        &::after {
          content: "必須";
          display: inline-block;
          width: 64px;
          background: v.$green;
          @include m.fz(14);
          line-height: 1.8em;
          text-align: center;
          font-weight: bold;
          color: v.$white;
          border-radius: 16px;
          margin-right: 14px;
          @include m.sp {
            margin-left: 10px;
          }
        }
      }
    }
    input {
      display: block;
      width: 100%;
      padding: 8px 6px;
      box-sizing: border-box;
    }
    select {
      padding: 8px 6px;
      width: 100%;
    }
    textarea {
      resize: vertical;
      width: 100%;
      height: 300px;
    }
    &.subtxt {
      position: relative;
      padding-bottom: 34px;
      .sub {
        @include m.fz(14);
        position: absolute;
        bottom: 10px;
        left: 35%;
      }
    }
    &.type02 {
      border-bottom: 1px solid v.$green;
      margin-bottom: 50px;
    }
  }
  &_one {
    width: 65%;
    @include m.sp {
      display: block;
      width: 100%;
    }
    .formCmn_cmn_inner {
      width: 100%;
    }
  }
  &_two {
    width: 65%;
    display: flex;
    justify-content: space-between;
    @include m.sp {
      width: 100%;
    }
    .formCmn_cmn_inner {
      width: 49%;
    }
  }
  &_three {
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m.sp {
      width: 100%;
    }
    .formCmn_cmn_inner {
      width: 30%;
      position: relative;
    }
    span {
      display: inline-block;
      width: 2%;
      height: 1px;
      background: v.$black;
    }
  }
  &_check {
    width: 65%;
    margin-left: auto;
    margin-right: 0;
    margin-top: 30px;
    @include m.sp {
      display: block;
      width: 100%;
      margin-top: 20px;
    }
    input {
      display: none;
    }
    .mwform-checkbox-field-text {
      @include m.fz(16);
      position: relative;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        display: block;
        width: 22px;
        height: 22px;
        border: 1px solid v.$black;
        margin-right: 30px;
      }
      &::after {
        content: "";
        display: none;
        width: 14px;
        height: 7px;
        border-left: 4px solid v.$green;
        border-bottom: 4px solid v.$green;
        position: absolute;
        top: 47%;
        left: 2px;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    input:checked + .mwform-checkbox-field-text::after {
      display: block;
    }
  }

  .formEnd_ttl {
    @include m.fz(32);
    line-height: 1.5em;
    text-align: center;
    color: v.$green;
    margin-bottom: 54px;
    @include m.sp {
      @include m.fz(24);
      line-height: 1.5em;
    }
  }
  .formEnd_txt {
    @include m.fz(16);
    line-height: 1.5em;
    margin-top: 40px;
    & + .formEnd_txt {
      margin-top: 26px;
    }
  }
}
