@use "global/mixin" as m;
@use "global/variable" as v;

.c-diagonal {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: v.$green;
    transform: skewY(-15deg);
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: 87% top;
    z-index: -1;
    @include m.sp {
      transform: skewY(-20deg);
      transform-origin: 70% top;
    }
  }
}
