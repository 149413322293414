@charset "UTF-8";
//カラー
$black: #3b3b3b;
$black02: #707070;
$white: #fff;
$gray: #707070;
$light-gray: #f7f7f7;
$red: #e60012;
$yellow: #f08300;
$blue: #00a0e9;
$green: #a4cb62;
$green02: #f8ffed;
$green03: #f1ffd9;
$green04: #e9f7da;
$paple: #b60081;
$orange: #f5be5b;

//フォント指定
@import url(//use.typekit.net/uey4htd.css);
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
@import url(//fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;600;700&display=swap);

$font_yumin: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP",
  "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
$font_yugo: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
  "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font_yugo_bold: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ",
  sans-serif;
$font_noto: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3",
  "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo,
  sans-serif;
$font_futura: futura-pt, sans-serif;

//アイコンフォント
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"),
    url("../fonts/icomoon.ttf") format("truetype"),
    url("../fonts/icomoon.woff") format("woff"),
    url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

$icon_mail: "\e900"; //mail
$icon_tw: "\ea96"; //twitter
// $icon_tel: "\e904";//tel
// $icon_link: "\e90f"; //別窓表示
// $icon_map:"\e948"; //MAP
// $icon_fb: "\ea90"; //Facebook
// $icon_insta: "\ea92"; //instagram
// $icon_google: "\ea88"; //google
// $icon_youtube: "\ea9d"; //youtube
// $icon_tra_right: "\e903"; //三角矢印右
// $icon_tra_left: "\e902"; //三角矢印左
// $icon_tra_bottom: "\e901"; //三角矢印下
// $icon_tra_top: "\e908"; //三角矢印上*/
