@use "global/mixin" as m;
@use "global/variable" as v;

.c-frame {
  border: 1px solid v.$green;

  &_title {
    font-size: 24px;
    font-weight: bold;
    line-height: calc(36 / 24);
    color: v.$black;
    max-width: 48rem;
    width: 100%;

    @include m.sp {
      font-size: 2rem;
    }
  }

  &_info {
    @include m.fz(16);
    line-height: 1.8;
    color: v.$black02;

    @include m.pc {
      border-left: 1px solid v.$green;
      margin-left: 40px;
      padding-left: 40px;
    }

    @include m.sp {
      margin-top: 1rem;
    }

    span {
      @include m.fz(20);
      line-height: 1.8;
    }
  }

  &_upper {
    display: flex;
    padding: 40px 56px;

    @include m.sp {
      display: block;
      padding: 2rem 2.8rem;
    }
  }

  &_lower {
    padding: 40px 40px 56px 40px;
    display: flex;
    justify-content: space-between;
    gap: 0 40px;
    border-top: 1px solid v.$green;

    @include m.sp {
      display: block;
      padding: 2rem 2.8rem;
    }
  }

  &_text {
    max-width: 56rem;
    width: 100%;

    @include m.sp {
      margin-top: 2rem;
    }
  }
}
