@use "global/mixin" as m;
@use "global/variable" as v;

.c-container {
  display: flex;

  &--block {
    @include m.sp {
      display: block;
    }
  }

  &--aic {
    align-items: center;
  }

  &--sb {
    justify-content: space-between;
  }

  &_item {
    width: 100%;
  }
}
