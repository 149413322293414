@use "global/mixin" as m;
@use "global/variable" as v;

.p-it {
  // FIXME: ITページ編集時にcommonクラスに変える
  &_subTtl {
    background-color: v.$white;
    text-align: center;
    font-family: v.$font_yumin;
    @include m.fz(24);
    line-height: calc(40 / 24);
    color: v.$black;
    padding: 1rem;
    @include m.sp {
      font-size: 2rem;
    }
  }

  &_strong {
    @include m.fz(24);
    color: v.$green;
    font-weight: bold;
    text-align: center;
    line-height: calc(36 / 24);
    @include m.sp {
      font-size: 2rem;
    }
  }

  &_fill {
    background-color: v.$white;
    padding: 2rem 6.5%;
  }

  &_inner {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }
}
