@use "global/mixin" as m;
@use "global/variable" as v;

.c-intro {
  width: 100%;

  &_heading {
    margin: 0 0 32px;
    font-family: v.$font_yumin;
    @include m.fz(28);
    line-height: 1.6;
    font-weight: 600;
    color: v.$black;
    position: relative;

    @include m.sp {
      margin: 0 0 40px;
    }

    &:after {
      content: "";
      width: 183px;
      height: 1px;
      background-color: v.$green;
      position: absolute;
      bottom: -16px;
      left: 0;
    }
  }

  &_text {
    @include m.fz(16);
    line-height: 1.8;
    color: v.$black02;
    & + .c-intro_text.type02 {
      margin-top: 20px;
    }
    span {
      font-weight: bold;
      color: v.$black;
    }
  }

  &_pic {
    margin: 32px 0 0;
  }

  &_list {
    position: relative;
    margin: 50px 0;
    @include m.sp {
      &_ttl {
        width: 80%;
        background: v.$green;
        @include m.fz(18);
        font-weight: bold;
        text-align: center;
        color: v.$white;
        padding: 20px 0;
        margin: 0 auto;
        position: relative;
        z-index: 1;
      }
      &_cont {
        width: 100%;
        background: v.$green03;
        padding: 50px 20px 30px;
        margin-top: -20px;
        &_cmn {
          width: 100%;
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent transparent transparent v.$green;
            border-width: 6px 0 6px 8px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
